export const countriesNoEnglishTestRequired = [
"antigua-and-barbuda",
"australia",
"bahamas",
"barbados",
"belize",
"canada",
"dominica",
"grenada",
"guyana",
"jamaica",
"new-zealand",
"st-kitts-and-nevis",
"st-lucia",
"st-vincent-and-the-grenadines",
"trinidad-and-tobago",
"usa"
]