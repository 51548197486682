export const checklistQuestions = [
'It\'s dated within the last six months.',
'It\'s in English OR you\'ve also had it officially translated.',
'It\'s the original document, not a print-out.',
'It won\'t expire within the next six months.', 
'It\'s in color.',
'You\'ve printed it out.',
'You\'ve signed and dated it.',
'They are all dated within the last year.',
'It\'s printed on company letterhead paper.',
'It specifies their gross annual salary.',
'It says how long they\'ve earned that salary level.',
'It says how you met and how long you\'ve been together.',
'It says you are in a subsisting relationship and intend to live together permanently in the UK.',
'It describes where you will live and how much space there is at the property.',
'It describes their current employment and financial situation.',
'It includes the photo page and any visa or entry stamps.',
'It\'s the page with the sponsor\'s name and photo.',
'It\'s a print-out, not the original document.',
`It's an IELTS Life Skills A1 or A2 certificate (must be A2 if you're applying to extend your visa) OR it's a UK-recognized degree that was taught in English.`,  
'It\'s not expired.',
'Every section is complete.',
'You\'ve submitted it online.',
'You\'ve received the confirmation email.',
'You\'ve booked it online.',
'It specifies their job title.',
'It says how long they have been employed.',
'It says whether they are employed on a permanent or temporary basis.',
'It\'s dated within one month from when you submit the application.',
'They cover a minimum of at least six months in a row.',
'They\'re all dated within the last 12 months.',
'It shows the name of the employer.',
'They\'re dated within the last 12 months.',
'They show the money going into the account.',
'It\'s the original document OR it\'s a print-out certified by bank staff on every page OR it\'s a print-out and you\'ve also got a letter from the bank.',
'They show the money has been in the account for at least six months.',
'It\'s their most recent P60.',
'It\'s a mortgage statement OR a document issued by HM Land Registry or the equivalent authority in that country.',
'It shows their name.',
'It\'s issued by a UK-approved tuberculosis test provider in your country.',
'It includes a copy or a print-out of their latest annual self-assessment tax return OR the official Statement of Account of their business.',
'It includes bank statments showing their income from self-employment.',
'It includes evidence of ongoing trading.',
'It includes their business accounts for the last full financial year OR their VAT return if turnover is above £79,000 OR an operating license.',
'If their self-employment is outside the UK, it includes equivalent documentation from that country.',
'It says who owns the property.',
'It describes the number of rooms at the property and says who lives there.',
'It says the owner allows you to both live there as long as you want without paying rent.',
'It shows different occassions such as weddings, holidays or birthdays.',
'It covers a range of different dates and places.',
'It shows both your names.',
'It includes a joint tenancy agreement, shared bills or similar evidence.',
'It covers the last two years or longer.',
'It covers a range of different dates.',
'It shows you making plans together.',
'It says the full rent is paid on time each month.',
'They\'re from as long ago as possible.',
'It says they will support the applicant financially and provide accommodation.',
'It shows the sponsor\'s name.',
'👈 Check this first requirement by clicking here.',
'Check each requirement carefully.',
'Finally, check the third requirement. You\'ve got this! 💯 Now, go and explore more items.'
];