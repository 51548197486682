
export const countriesThatRequireTBTest = [
	"afghanistan",
	"algeria",
	"angola",
	"armenia",
	"azerbaijan",
	"bangladesh",
	"belarus",
	"benin",
	"bhutan",
	"bolivia",
	"botswana",
	"brunei",
	"myanmar",
	"burkina-faso",
	"burundi",
	"cambodia",
	"cape-verde",
	"central-african-republic",
	"chad",
	"cameroon",
	"china",
	"cote-d-ivoire",
	"democratic-republic-of-the-congo",
	"djibouti",
	"dominican-republic",
	"ecuador",
	"equatorial-guinea",
	"eritrea",
	"ethiopia",
	"gabon",
	"georgia",
	"ghana",
	"guatemala",
	"guinea",
	"guinea-bissau",
	"guyana",
	"haiti",
	"hong-kong",
	"india",
	"indonesia",
	"iraq",
	"kazakhstan",
	"kenya",
	"kiribati",
	"kyrgyzstan",
	"laos",
	"lesotho",
	"liberia",
	"macao",
	"madagascar",
	"malawi",
	"malaysia",
	"mali",
	"marshall-islands",
	"mauritania",
	"micronesia",
	"moldova",
	"mongolia",
	"mozambique",
	"myanmar",
	"namibia",
	"nepal",
	"niger",
	"nigeria",
	"north-korea",
	"pakistan",
	"palau",
	"papua-new-guinea",
	"panama",
	"paraguay",
	"peru",
	"philippines",
	"russia",
	"rwanda",
	"senegal",
	"sierra-leone",
	"solomon-islands",
	"somalia",
	"south-africa",
	"south-korea",
	"south-sudan",
	"sri-lanka",
	"sudan",
	"suriname",
	"tajikistan",
	"tanzania",
	"togo",
	"thailand",
	"turkmenistan",
	"tuvalu",
	"uganda",
	"ukraine",
	"uzbekistan",
	"vanuatu",
	"vietnam",
	"zambia",
	"zimbabwe"
];
